export const Pages = {
    HOME: '/',
    FIND_JOBS: '/find-jobs',
    FIND_TALENT: '/find-talent',
    EBOOK: '/ebook',
    ABOUT_US: '/about-us',
    PRIVACY_POLICY: '/privacy-policy',
    TERMS_OF_USE: '/terms-of-use',
    RESULT: '/result/',
    RESET_PASSWORD_SUCCESS: '/reset-password-success',
    DASHBOARD: '/dashboard',
    DASHBOARD_RESUMES: '/dashboard/resumes',
    DASHBOARD_JOBS: '/dashboard/jobs',
    DASHBOARD_INTERVIEWS: '/dashboard/interviews',
    DASHBOARD_PROFILE: '/dashboard/profile',
    CAREER_PATH: '/dashboard/career-path',
    WEEKLY_TREND: '/dashboard/weekly-trend',
    RESUME_GPT: '/resume-gpt',
    RESUME_GPT_START: '/resume-gpt/start',
    RESUME_GPT_RESUME: '/resume-gpt/resume/',
    RESUME_GPT_SELECT_TEMPLATE: '/resume-gpt/result/',
    JD_GPT_HOME: '/jd-gpt/home',
    JD_GPT_OVERVIEW: '/jd-gpt/overview/'
}

export const ExternalLink = {
    KE_HOME: 'https://kesolutions.ca/home',
    FACEBOOK: 'https://www.facebook.com/Jobster-104575948190743',
    LINKEDIN: 'https://www.linkedin.com/company/jobster-io/',
    PIKLET: 'https://www.kesolutions.ca/piklet/',
    LOEWE_CALENDAR: 'https://calendly.com/loewe-ke'
}

const nodeApiHostname = 'https://api.jobster.io/';
const apiHostname = 'https://legacy.jobster.io/api/v1/';
const api2Hostname = 'https://api2.jobster.io/api/v1/';

export const APIs = {
    UPLOAD_JD: api2Hostname + 'upload_jd',
    UPLOAD_RESUME: nodeApiHostname + 'resume/upload',
    RANK_SAMPLE_JOBS: apiHostname + 'rank_sample_jobs',
    GET_JOB_LISTINGS: apiHostname + 'get_job_listings',
    SAVE_JOB: apiHostname + 'save_job',
    UNSAVE_JOB: apiHostname + 'unsave_job',
    LOGIN: apiHostname + 'login',
    SIGNUP: apiHostname + 'signup',
    FORGOT_PASSWORD: apiHostname + 'forgot_password',
    SEND_ACCOUNT_VERIFICATION_EMAIL: apiHostname + 'send_account_verification_email',
    REQUEST_DEMO: apiHostname + 'request_demo',
    DOWNLOAD_EBOOK: apiHostname + 'download_ebook',
    RESET_PASSWORD: apiHostname + 'reset_password',
    EDIT_NAME: apiHostname + 'edit_name',
    EDIT_PHONE: apiHostname + 'edit_phone',
    EDIT_PASSWORD: apiHostname + 'edit_password',
    SKILL_SUGGESTIONS: apiHostname + 'skill_suggestions',
    EDIT_WORK_EXPERIENCE: apiHostname + 'edit_work_experience',
    EDIT_SKILLS: apiHostname + 'edit_skills',
    GET_SAVED_JOBS: apiHostname + 'get_saved_jobs',
    GET_RESUMES: apiHostname + 'get_resumes',
    DELETE_RESUME: apiHostname + 'delete_resume',
    GET_INTERVIEWS: apiHostname + 'get_interviews',
    DELETE_INTERVIEW: apiHostname + 'delete_interview',
    ADD_INTERVIEW: apiHostname + 'add_interview',
    GET_DASHBOARD: apiHostname + 'get_dashboard',
    GET_FILE: api2Hostname + 'get_file',
    GET_RESUME: nodeApiHostname + 'resume/:id',
    EXTRACT_RESUME: nodeApiHostname + 'resume/:id/extract',
    UPDATE_EXTRACTION: nodeApiHostname + 'resume/:id/extraction',
    GENERATE_RESUME: nodeApiHostname + 'resume/:id/generate',
    DOWNLOAD_RESUME: nodeApiHostname + 'resume/:id/download',
    EXTRACT_JD: nodeApiHostname + 'jd/:id/extract',
    GENERATE_JD: nodeApiHostname + 'jd/:id/generate',
    TEST_PROMPT: nodeApiHostname + 'resume/test-prompt'
}

export const EmailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PhoneNumberPattern = /^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;