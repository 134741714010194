import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Alert, Col, Divider, Row, Typography } from 'antd';
import { Document, Page, pdfjs } from 'react-pdf';
import { SyncOutlined } from '@ant-design/icons';
import { APIs } from '../../utils/constants';
import { useParams } from 'react-router';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { isDesktop } from '../../utils/window';
import Text from '../../components/text';
import Loading from '../../assets/images/loading.mp4';
import Button from '../../components/button';
import 'react-pdf/dist/Page/AnnotationLayer.css';

const ResumeGptSelectTemplate = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [resumeURL, setResumeURL] = useState(null);
    const [purchased, setPurchased] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const scale = isDesktop() ? 1.0 : 0.5;
    const hasFetched = useRef(false);

    const onDocumentLoadSuccess = useCallback(({ numPages }) => {
        setNumPages(numPages);
        setPageNumber(1);
    }, []);

    const changePage = useCallback((offset) => {
        setPageNumber((prevPageNumber) => prevPageNumber + offset);
    }, []);

    const previousPage = useCallback(() => {
        changePage(-1);
    }, [changePage]);

    const nextPage = useCallback(() => {
        changePage(1);
    }, [changePage]);

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    }, []);

    const fetchResume = useCallback(async (regenerate = false) => {
        try {
            setLoading(true);
            const generateResumeResponse = await fetch(APIs.GENERATE_RESUME.replace(':id', id), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ regenerate }),
            });

            if (generateResumeResponse.ok) {
                const generateResumeData = await generateResumeResponse.arrayBuffer();
                const blob = new Blob([generateResumeData], { type: 'application/pdf' });
                const pdfUrl = URL.createObjectURL(blob);
                setResumeURL(pdfUrl);
            }

            const getResumeResponse = await fetch(APIs.GET_RESUME.replace(':id', id), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (getResumeResponse.ok) {
                const getResumeData = await getResumeResponse.json();
                setPurchased(getResumeData.paid);
            }
            setLoading(false);
        } catch (error) {
            // Handle error (e.g., show a message to the user)
        }
    }, [id]);

    useEffect(() => {
        if (!hasFetched.current) {
            fetchResume(false);
            hasFetched.current = true;
        }
    }, [id, fetchResume]);

    const handleDownload = async (type) => {
        try {
            const response = await fetch(APIs.DOWNLOAD_RESUME.replace(':id', id), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ type })
            });
            const data = await response.json();

            if (response.ok) {
                const link = document.createElement('a');
                link.href = data.link;
                link.download = 'generated_resume';
                link.click();
            } else {
                setErrorMessage(data.error);
            }
        } catch (error) {
            setErrorMessage('Something went wrong');
        }
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>
                    {`${useIntl().formatMessage({ id: 'resume-gpt::result::title-name' })}`}
                </title>
            </Helmet>
            {loading ? (
                <Row className='my-5 h-100' justify='center' align='middle'>
                    <div className='text-center'>
                        <video loop muted autoPlay playsInline src={Loading} className='mb-3' preload='auto' />
                        <Typography.Title level={3}>
                            <Text id='resume-gpt::result::text::loading1' />
                        </Typography.Title>
                        <Typography.Title level={3}>
                            <Text id='resume-gpt::result::text::loading2' />
                        </Typography.Title>
                    </div>
                </Row>
            ) : (
                <>
                    <Row className='position-relative'>
                        {errorMessage &&
                            <Alert
                                className='position-absolute w-100 text-danger text-center'
                                style={{ left: 0, top: 0 }}
                                message={errorMessage}
                                type='error'
                                showIcon={false}
                                banner
                            />
                        }
                    </Row>
                    <Row className='my-5' justify='center' align='top'>
                        <Col xxl={12} xl={14} className='order-2 order-sm-1'>
                            <Row>
                                <Col xxl={{ span: 12, offset: 12 }} xl={{ span: 19, offset: 4 }}>
                                    <Document
                                        file={resumeURL}
                                        onLoadSuccess={onDocumentLoadSuccess}
                                        className='p-4 border rounded'
                                    >
                                        <Page pageNumber={pageNumber} renderAnnotationLayer={false} renderTextLayer={false} scale={scale} />
                                    </Document>
                                    <Row className='mt-4' justify='center'>
                                        <Col xs={24}>
                                            <Typography.Paragraph className='text-center'>
                                                <Text id='resume-gpt::result::text::page' values={{
                                                    current: () => pageNumber || (numPages ? 1 : '--'),
                                                    total: () => numPages || '--'
                                                }} />
                                            </Typography.Paragraph>
                                            <Button
                                                type='primary'
                                                disabled={pageNumber <= 1}
                                                onClick={previousPage}
                                                className='w-50 text-center'
                                                size='sm'
                                            >
                                                <Text id='resume-gpt::result::button::previous' />
                                            </Button>
                                            <Button
                                                type='primary'
                                                disabled={pageNumber >= numPages}
                                                onClick={nextPage}
                                                className='w-50 text-center'
                                                size='sm'
                                            >
                                                <Text id='resume-gpt::result::button::next' />
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col xxl={12} xl={10} className='order-1 order-sm-2 mb-4'>
                            <Row className='mt-sm-5'>
                                <Col xxl={{ span: 12, offset: 1 }} xl={{ span: 18 }} className='text-center px-4'>
                                    <Button solid={false} onClick={() => fetchResume(true)} disabled={purchased} >
                                        <SyncOutlined className='mr-2' style={{ fontSize: '15px' }} />
                                        <Text id='resume-gpt::result::button::rewrite' />
                                    </Button>
                                    <div className='mt-5'>
                                        {
                                            purchased ?
                                                <>
                                                    <Divider className='border-blue'>
                                                        <Text id='resume-gpt::result::text::download' />
                                                    </Divider>
                                                    <Row>
                                                        <Col span={10} offset={1}>
                                                            <Button onClick={() => handleDownload('pdf')} size='sm'>
                                                                <Text id='resume-gpt::result::button::pdf' />
                                                            </Button>
                                                        </Col>
                                                        <Col span={10} offset={2}>
                                                            <Button onClick={() => handleDownload('docx')} size='sm'>
                                                                <Text id='resume-gpt::result::button::docx' />
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </>
                                                :
                                                <>
                                                    <Divider className='border-blue'>
                                                        <Text id='resume-gpt::result::text::purchase' />
                                                    </Divider>
                                                    <Button onClick={() => handleDownload(null)}>
                                                        <Text id='resume-gpt::result::button::purchase' />
                                                    </Button>
                                                </>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </>
            )}
        </React.Fragment>
    );
};

export default ResumeGptSelectTemplate;
