import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Row, Col, Table, Tooltip, Typography, Skeleton } from 'antd';
import { InfoCircleFilled, FileSearchOutlined } from '@ant-design/icons';
import { Pencil, Trash } from 'react-bootstrap-icons';
import { APIs, Pages } from '../../utils/constants';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { isDesktop } from '../../utils/window';
import { v4 as uuidv4 } from 'uuid';
import Text from '../../components/text';
import Button from '../../components/button';
import PropTypes from 'prop-types';
import EditEducation from '../../components/modal/edit-education';
import EditWork from '../../components/modal/edit-work';
import EditProject from '../../components/modal/edit-project';

function ResumeOverview() {
    const history = useHistory();

    const { id } = useParams();
    const [form] = Form.useForm();

    const FormItem = ({ name, label, rules }) => {
        return (
            <Form.Item className='px-5' name={name} label={label} rules={rules || []}>
                {!loading && <Input />}
                {loading && <Skeleton.Input className='w-100' active='true' size='large' />}
            </Form.Item>
        );
    };
    FormItem.propTypes = { // "casts" the props
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        rules: PropTypes.arrayOf(PropTypes.object)
    };

    // Modal
    const editEducationModal = useRef();
    const editWorkModal = useRef();
    const editProjectModal = useRef();

    // States
    const [loading, setLoading] = useState(true);
    const [extraction, setExtraction] = useState({});
    const [educationId, setEducationId] = useState(null); // for editing, which education you are "looking at"
    const [educations, setEducations] = useState([]);

    const [workId, setWorkId] = useState(null); // for editing, which education you are "looking at"
    const [works, setWorks] = useState([]);

    const [projectId, setProjectId] = useState(null); // for editing, which education you are "looking at"
    const [projects, setProjects] = useState([]);

    const [isFormValid, setIsFormValid] = useState(false);

    // Education Functions
    const addEducation = (education) => {
        const updatedEducations = [education, ...educations];
        setEducations(updatedEducations);
    }
    const editEducation = changedEdu => {
        const index = educations.findIndex((education) => education.id === educationId);

        if (index !== -1) {
            const updatedEducations = [...educations];
            updatedEducations.splice(index, 1, {
                ...updatedEducations[index],
                ...changedEdu
            });
            setEducations(updatedEducations);
        }
    }
    const deleteEducation = id => {
        const updatedEducations = educations.filter(education => education.id !== id);
        setEducations(updatedEducations);
    }

    // Work Functions
    const addWork = work => {
        const updatedWorks = [work, ...works];
        setWorks(updatedWorks);
    }
    const editWork = changedWork => {
        const index = works.findIndex((work) => work.id === workId);

        if (index !== -1) {
            const updatedWorks = [...works];
            updatedWorks.splice(index, 1, {
                ...updatedWorks[index],
                ...changedWork
            });
            setWorks(updatedWorks);
        }
    }
    const deleteWork = id => {
        const updatedWorks = works.filter(work => work.id !== id);
        setWorks(updatedWorks);
    }

    // Project Functions
    const addProject = project => {
        const updatedProjects = [project, ...projects];
        setProjects(updatedProjects);
    }
    const editProject = changedEdu => {
        const index = projects.findIndex((project) => project.id === projectId);

        if (index !== -1) {
            const updatedProjects = [...projects];
            updatedProjects.splice(index, 1, {
                ...updatedProjects[index],
                ...changedEdu
            });
            setProjects(updatedProjects);
        }
    }
    const deleteProject = id => {
        const updatedProjects = projects.filter(project => project.id !== id);
        setProjects(updatedProjects);
    }

    // Update Extraction
    const updateExtraction = async () => {
        //eslint-disable-next-line no-unused-vars
        const removeId = (items) => items.map(({ id, ...rest }) => rest);

        const updatedExtraction = {
            ...extraction,
            basic_information: form.getFieldsValue(),
            education_information: removeId(educations),
            work_experience: removeId(works),
            other_experience: removeId(projects)
        };

        const resumeId = id == null ? uuidv4() : id;

        const response = await fetch(APIs.UPDATE_EXTRACTION.replace(':id', resumeId), {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                extraction: updatedExtraction
            })
        });
        if (response.ok) {
            history.push(Pages.RESUME_GPT_SELECT_TEMPLATE + resumeId)
        }
    }

    useEffect(() => {
        const fetchResumeData = async () => {
            const response = await fetch(APIs.EXTRACT_RESUME.replace(':id', id), {
                method: 'POST'
            });
            const r = await response.json();
            if (response.ok) {
                setExtraction(r);
                form.setFieldsValue(r.basic_information);
                setEducations(r.education_information?.map((education, index) => ({
                    id: (index + 1) * (-1), // indexes with values starting at -1, negative so that they are different from the index of added educations
                    name_of_institution: education['name_of_institution'],
                    degree_type: education['degree_type'],
                    major: education['major'],
                    start_date: education['start_date'],
                    end_date: education['end_date'],
                    gpa: education['gpa'],
                    details: education['details'],
                })));
                setWorks(r.work_experience?.map((work, index) => ({
                    id: (index + 1) * (-1),
                    company_name: work['company_name'],
                    job_title: work['job_title'],
                    start_date: work['start_date'],
                    end_date: work['end_date'],
                    location: work['location'],
                    details: work['details'],
                })));
                setProjects(r.other_experience?.map((work, index) => ({
                    id: (index + 1) * (-1),
                    project_name: work['project_name'],
                    affiliated_organization: work['affiliated_organization'],
                    start_date: work['start_date'],
                    end_date: work['end_date'],
                    details: work['details'],
                })));
                validateForm();
                setLoading(false);
            }
        };
        if (id == null) {
            setLoading(false);
        } else {
            fetchResumeData();
        }
    }, []);

    const validateForm = async () => {
        setTimeout(async () => {
            try {
                await form.validateFields();
                setIsFormValid(true);
            } catch (errorInfo) {
                setIsFormValid(false);
            }
        }, 0);
    };

    const actionsCol = (editModal, setIdFunction, deleteFunction) => ({
        title: <Text id='dashboard::interviews::table::header::actions' />,
        dataIndex: 'actions',
        key: 'actions',
        ellipsis: true,
        width: '10%',
        render: (_, col) => (
            <div className='d-flex justify-content-center'>
                <Pencil className='cursor-pointer mr-3' onClick={() => {
                    editModal.current.showModal(col);
                    setIdFunction(col.id);
                }} />
                <Trash className='cursor-pointer' onClick={() => deleteFunction(col.id)} />
            </div>
        ),
    });

    const educationColumns = [
        { title: 'Institution', dataIndex: 'name_of_institution', key: 'name_of_institution', ellipsis: true, width: '20%' }, // title: <Text id='dashboard::interviews::table::header::job-title' />,
        { title: 'Degree', dataIndex: 'degree_type', key: 'degree_type', ellipsis: true, width: '20%' },
        { title: 'Major', dataIndex: 'major', key: 'major', ellipsis: true, width: '16%' },
        { title: 'Start Date', dataIndex: 'start_date', key: 'start_date', ellipsis: true, width: '12%' },
        { title: 'End Date', dataIndex: 'end_date', key: 'end_date', ellipsis: true, width: '12%' },
        { title: 'GPA', dataIndex: 'gpa', key: 'gpa', ellipsis: true, width: '10%' },
        actionsCol(editEducationModal, setEducationId, deleteEducation),
    ];

    const workColumns = [
        { title: 'Company', dataIndex: 'company_name', key: 'company_name', ellipsis: true, width: '24%' }, // title: <Text id='dashboard::interviews::table::header::job-title' />,
        { title: 'Job Title', dataIndex: 'job_title', key: 'job_title', ellipsis: true, width: '28%' },
        { title: 'Start Date', dataIndex: 'start_date', key: 'start_date', ellipsis: true, width: '12%' },
        { title: 'End Date', dataIndex: 'end_date', key: 'end_date', ellipsis: true, width: '12%' },
        { title: 'Location', dataIndex: 'location', key: 'location', ellipsis: true, width: '14%' },
        actionsCol(editWorkModal, setWorkId, deleteWork),
        // detailCol,
    ];

    const projectColumns = [
        { title: 'Project Name', dataIndex: 'project_name', key: 'project_name', ellipsis: true, width: '45%' },
        { title: 'Affiliated Organization', dataIndex: 'affiliated_organization', key: 'affiliated_organization', ellipsis: true, width: '21%' },
        { title: 'Start Date', dataIndex: 'start_date', key: 'start_date', ellipsis: true, width: '12%' },
        { title: 'End Date', dataIndex: 'end_date', key: 'end_date', ellipsis: true, width: '12%' },
        actionsCol(editProjectModal, setProjectId, deleteProject),
    ];

    return (
        <React.Fragment>
            <Row className='my-5'>
                <Col span={20} offset={2}>
                    <Row className='mb-5'>
                        <Col xs={24} md={{ span: 20, offset: 2 }} className='text-center'>
                            <Typography.Title>
                                {id != null && 'We\'ve analyzed your resume! Please review and confirm these details'}
                                {id == null && 'Please fill the details'}
                            </Typography.Title>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Typography.Title level={3}>
                                Contact Info
                            </Typography.Title>
                        </Col>
                    </Row>
                    <Form form={form} layout='vertical' onValuesChange={validateForm}>
                        <Row className='bg-gray mb-5 py-4'>
                            <Col xs={24} md={12}>
                                <FormItem
                                    name="full_name"
                                    label={
                                        <div className='d-flex align-items-center'>
                                            Name &nbsp;
                                            <Tooltip
                                                title={
                                                    <span>
                                                        <strong>Did you know?</strong> <br />
                                                        Research shows names easier to pronounce result in higher response rate.
                                                        But don&rsquo;t worry if your name is unique! Consider adding a pronunciation guide - for example, Siobhan (Shi-vawn).
                                                        It&rsquo;s a neat way to help others while showcasing your unique identity!
                                                    </span>
                                                }
                                            >
                                                <InfoCircleFilled />
                                            </Tooltip>
                                        </div>
                                    }
                                    rules={[{ required: true, message: <Text id='resume-gpt::resume-overview::name-required' /> }]}
                                />
                            </Col>
                            <Col xs={24} md={12}>
                                <FormItem
                                    name='link_to_linkedin' label="LinkedIn"
                                />
                            </Col>
                            <Col xs={24} md={12}>
                                <FormItem
                                    name='phone_number' label="Phone"
                                    rules={[{ required: true, message: <Text id='resume-gpt::resume-overview::phone-required' /> }]}
                                />
                            </Col>
                            <Col xs={24} md={12}>
                                <FormItem
                                    name='link_to_github' label="Github"
                                />
                            </Col>
                            <Col xs={24} md={12}>
                                <FormItem
                                    name='email_address'
                                    label={
                                        <div className='d-flex align-items-center'>
                                            Email &nbsp;
                                            <Tooltip
                                                title={
                                                    <span>
                                                        <strong>Did you know?</strong> <br />
                                                        Professional-looking email addresses often see higher response rates! It&rsquo;s
                                                        time to trade &rdquo;FluffyBunny123&rdquo; for something more polished that represents you.
                                                    </span>
                                                }
                                            >
                                                <InfoCircleFilled />
                                            </Tooltip>
                                        </div>
                                    }
                                    rules={[{ required: true, message: <Text id='resume-gpt::resume-overview::email-required' /> }]}
                                />
                            </Col>
                            <Col xs={24} md={12}>
                                <FormItem
                                    name='twitter' label="Twitter"
                                />
                            </Col>
                            <Col xs={24} md={12}>
                                <FormItem
                                    name='address' label="Address"
                                />
                            </Col>
                            <Col xs={24} md={12}>
                                <FormItem
                                    name='link_to_personal_portfolio' label="Portfolio"
                                />
                            </Col>
                        </Row>
                    </Form>
                    <Row>
                        <Col span={12}>
                            <Typography.Title level={3}>
                                Educations
                            </Typography.Title>
                        </Col>
                        <Col span={12} className='text-right'>
                            <Button size='sm' onClick={() => editEducationModal.current.showModal()}>
                                Add Education
                            </Button>
                        </Col>
                    </Row>
                    <Row className='mb-5'>
                        <Col>
                            <Table
                                dataSource={educations}
                                columns={educationColumns}
                                expandable={{
                                    expandedRowRender: col =>
                                        <div className='ml-5 mr-5 mt-2 p-0 mb-2'>
                                            <div className='mr-3 mb-2' style={{ fontWeight: 'bold' }}>
                                                {'Details:'}
                                            </div>
                                            {col.details}
                                        </div>,
                                    rowExpandable: col => col.details ? true : false,
                                }}
                                rowKey='id'
                                expandIcon={({ onExpand, record }) =>
                                    record.details ? ( // if no details, the icons are not rendered
                                        <FileSearchOutlined onClick={e => onExpand(record, e)} />
                                    ) : null
                                }
                                loading={loading}
                                scroll={isDesktop() ? {} : { x: 1000 }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={15} md={12}>
                            <Typography.Title level={3}>
                                Work Experiences
                            </Typography.Title>
                        </Col>
                        <Col xs={9} md={12} className='text-right'>
                            <Button size='sm' onClick={() => editWorkModal.current.showModal()}>
                                Add Work
                            </Button>
                        </Col>
                    </Row>
                    <Row className='mb-5'>
                        <Col>
                            <Table
                                dataSource={works}
                                columns={workColumns}
                                expandable={{
                                    expandedRowRender: col =>
                                        <div className='ml-5 mr-5 mt-2 p-0 mb-2'>
                                            <div className='mr-3 mb-2' style={{ fontWeight: 'bold' }}>
                                                {'Details:'}
                                            </div>
                                            {col.details}
                                        </div>,
                                    rowExpandable: col => col.details ? true : false,
                                }}
                                rowKey='id'
                                expandIcon={({ onExpand, record }) =>
                                    record.details ? ( // if no details, the icons are not rendered
                                        <FileSearchOutlined onClick={e => onExpand(record, e)} />
                                    ) : null
                                }
                                loading={loading}
                                scroll={isDesktop() ? {} : { x: 1000 }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Typography.Title level={3}>
                                Projects
                            </Typography.Title>
                        </Col>
                        <Col span={12} className='text-right'>
                            <Button size='sm' onClick={() => editProjectModal.current.showModal()}>
                                Add Project
                            </Button>
                        </Col>
                    </Row>
                    <Row className='mb-5'>
                        <Col>
                            <Table
                                dataSource={projects}
                                columns={projectColumns}
                                expandable={{
                                    expandedRowRender: col =>
                                        <div className='ml-5 mr-5 mt-2 p-0 mb-2'>
                                            <div className='mr-3 mb-2' style={{ fontWeight: 'bold' }}>
                                                {'Details:'}
                                            </div>
                                            {col.details}
                                        </div>,
                                    rowExpandable: col => col.details ? true : false,
                                }}
                                rowKey='id'
                                expandIcon={({ onExpand, record }) =>
                                    record.details ? ( // if no details, the icons are not rendered
                                        <FileSearchOutlined onClick={e => onExpand(record, e)} />
                                    ) : null
                                }
                                loading={loading}
                                scroll={isDesktop() ? {} : { x: 1000 }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12} className='text-center'>
                            <Button size='md' width={100} onClick={() => history.goBack()}>
                                Back
                            </Button>
                        </Col>
                        <Col span={12} className='text-center'>
                            <Button onClick={updateExtraction} disabled={loading || !isFormValid}>
                                Next
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <EditEducation
                ref={editEducationModal}
                addEducation={addEducation}
                editEducation={editEducation}
            />
            <EditWork
                ref={editWorkModal}
                addWork={addWork}
                editWork={editWork}
            />
            <EditProject
                ref={editProjectModal}
                addProject={addProject}
                editProject={editProject}
            />
        </React.Fragment>
    )
}

export default ResumeOverview;